// 公共地址路径
export default {
  namespaced: true,
  state: {
    visible: false
  },
  mutations:{
    ToggleCommentDrawer( state, payload) {
      state.visible = payload
    }
  },
}
