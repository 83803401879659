import request from '@/utils/request'
import qs from 'qs'
const baseURL = process.env.NODE_ENV === 'production' ? '' : '/api';
// 公共地址路径
const conUrl = '/console'
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // 团队/问卷链接邀请未登录状态下返回展示数据
    getInviteHref(vuex, params = {}) {
      return request({
        url: `${conUrl}/invite_href`,
        method: 'GET',
        params
      });
    },
    // 通过手机号邀请成员发送短信
    postInviteSms(vuex, data =null) {
      return request({
        url: `${conUrl}/invite_sms`,
        method: 'POST',
        data,
      });
    },
    // 登录条件下判断审核状态
    postTeamRolesStatus(vuex, data = {}) {
      return request({
        url: `${conUrl}/team_roles/status`,
        method: 'POST',
        data,
      });
    },
    // 直接加入/提交审批
    postTeamRolesApply(vuex, data = {}) {
      return request({
        url: `${conUrl}/team_roles/apply`,
        method: 'POST',
        data,
      });
    },
    // 设置加入团队是否需要审批
    patchTeamRolesCheck(vuex, data = {}) {
      return request({
        url: `${conUrl}/team_roles/check`,
        method: 'PATCH',
        data,
      });
    },
    // 团队加入管理员审批
    patchTeamRolesJoinCheck(vuex, data = {}) {
      return request({
        url: `${conUrl}/team_roles/join_check`,
        method: 'PATCH',
        data,
      });
    },
    // 团队/审批时，展示申请信息
    getTeamRolesApplyMsg(vuex, params = {}) {
      return request({
        url: `${conUrl}/team_roles/apply_msg`,
        method: 'GET',
        params
      });
    },
    // 申请加入团队列表
    getTeamRolesJoinList(vuex, params = {}) {
      return request({
        url: `${conUrl}/team_roles/join_list`,
        method: 'GET',
        params
      });
    },
    // 管理成员权限获取权限列表
    getTeamRolesRoleList(vuex, params = {}) {
      return request({
        url: `${conUrl}/team_roles/role_list`,
        method: 'GET',
        params
      });
    },
    // 修改成员角色
    patchTeamRolesSaveRole(vuex, data = {}) {
      return request({
        url: `${conUrl}/team_roles/save_role`,
        method: 'PATCH',
        data,
      });
    },
    // 修改团队名称
    patchTeamRolesTeamName(vuex, data = {}) {
      return request({
        url: `${conUrl}/team_roles/team_name`,
        method: 'PATCH',
        data,
      });
    },
    // 移交团队
    postTeamRolesTransfer(vuex, data = {}) {
      return request({
        url: `${conUrl}/team_roles/transfer`,
        method: 'POST',
        data,
      });
    },
    // 解散团队
    delTeamRolesDismiss(vuex, data = {}) {
      return request({
        url: `${conUrl}/team_roles/dismiss`,
        method: 'DELETE',
        data,
      });
    },
    // 删除成员
    delTeamRolesUser(vuex, data = {}) {
      return request({
        url: `${conUrl}/team_roles/user`,
        method: 'DELETE',
        data,
      });
    },
    // 复制链接生成code码
    postTeamRolesCode(vuex, data = {}) {
      return request({
        url: `${conUrl}/team_roles/code`,
        method: 'POST',
        data,
      });
    },
    // 申请加入待审核个数
    getTeamRolesRoleCheckCount(vuex, params = {}) {
      return request({
        url: `${conUrl}/team_roles/check_count`,
        method: 'GET',
        params
      });
    },
    // 退出团队
    delTeamRolesLeave(vuex, data = {}) {
      return request({
        url: `${conUrl}/team_roles/leave`,
        method: 'DELETE',
        data,
      });
    },
    // 团队设置界面信息
    getTeamsSetting(vuex, params = {}) {
      return request({
        url: `${conUrl}/teams_role/team_setting`,
        method: 'GET',
        params
      });
    },
    // 移交团队成员列表
    getTeamUsers(vuex, params = {}) {
      return request({
        url: `${conUrl}/teams_role/user_list`,
        method: 'GET',
        params
      });
    },
    // 切换团队-团队列表
    getTeamsPerson(vuex, params = {}) {
      return request({
        url: `${conUrl}/teams/person`,
        method: 'GET',
        params
      });
    },
    // 切换团队
    patchTeamsChange(vuex, data = {}) {
      return request({
        url: `${conUrl}/teams/change`,
        method: 'PATCH',
        data,
      });
    },
    // 获取用户页面/按钮权限
    getAuthorizationsRoles(vuex, params = {}) {
      return request({
        url: `${conUrl}/authorizations/roles`,
        method: 'GET',
        params
      });
    },
    // 获取用户头像信息
    getUserInfo(vuex, params = {}) {
      return request({
        url: `${conUrl}/teams_role/user_info`,
        method: 'GET',
        params
      });
    },
    // 获取知情同意书信息
    getTeamSetting(vuex, params = {}) {
      return request({
        url: `${conUrl}/teams_role/team_setting`,
        method: 'GET',
        params
      });
    },
    // 知情通知书配置
    postInformedConsents(vuex, data = {}) {
      return request({
        url: `${conUrl}/teams_roles/informed_consents`,
        method: 'POST',
        data,
      });
    },
  }
};