const documentLibrary = () => import(/* webpackChunkName: 'home' */ '@views/DocumentLibrary/index.vue');

const router = [
  {
    path: 'documentLibrary',
    name: 'documentLibrary',
    meta: { title: '文档库' },
    component: documentLibrary,
  },
];
export default router;
