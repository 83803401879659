const contact = () => import(/* webpackChunkName: 'home' */ '@views/Contact/index.vue');

const router = [
  {
    path: 'contact',
    name: 'contact',
    meta: { title: '联系人' },
    component: contact,
  },
];
export default router;
