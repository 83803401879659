export default {
  namespaced: true,
  state: {
    // 图表分析，全屏
    fullScreenModel:{
      // 翻页的总数
      qsNowCount:0,
      // 当前页
      nowQsItemIndex:0,
    },
    diagramList:[]
  },
  getters: {},
  mutations: {},
  actions: {
    changeNowCount({state},num){
      state.fullScreenModel.qsNowCount = num
    },
    changNowQsItemIndex({state},num){
      state.fullScreenModel.nowQsItemIndex = num
    },
    diagramList({state},data){
      state.diagramList = data
    },
  },
};
