export default {
    namespaced: true,
    state: {
      // 返回的路径
      centerUrl: '',
    },
    getters: {},
    mutations: {},
    actions: {
      changeCenterUrl ({ state }, url) {
        state.centerUrl = url
      },
    },
  };
  