import request from '@/utils/request'
import qs from 'qs'
// 公共地址路径
const conUrl = '/console'
export default {
  namespaced: true,
  state: {
    // 题型
    selectList:[],
    // 缓存当前页面item
    bptoItem:[],
    // 是否打开弹窗
    addCard:false,
    // 是否全屏显示
    fullScreen:false,
    // 当前查看数据
    fullScreenIndex:-1,
    // 查看详情抽屉
    lookAll:false,
    // 当前抽屉查看id
    sceneId:-1,
    // 重命名
    resetName:false,
    // 添加使用题型
    questionId:-1,
    // 场景用total
    saveBptoTotal:-1,
  },
  mutations:{},
  actions:{
    // 存total
    saveBptoTotal({state},data){
      state.saveBptoTotal = data
    },
    // 获取题型
    changeSelectList({state},data){
      state.selectList = data
    },
    // 缓存当前页面item
    saveBptoItem({state},data){
      state.bptoItem = data
    },
    // 控制弹窗
    changeAddCard({state},data=false){
      state.addCard = data
      console.log('addCard',state);
    },
    // 点击全屏显示
    changeFullScreen({state},data=false){
      state.fullScreen = data
      console.log('fullScreen',state);
    },
    // 控制弹窗下标
    changeFullScreenIndex({state},data=-1){
      state.fullScreenIndex = data
      console.log('changeFullScreenIndex',state.fullScreenIndex);
    },
    // 查看详情弹窗是否显示
    changeLookAll({state},data=false){
      state.lookAll = data
      console.log('changeLookAll',state);
    },
    // 当前抽屉查看id
    changeSceneId({state},data=-1){
      state.sceneId = data
      console.log('changeSceneId',state);
    },
    // 点击全屏显示
    changeResetName({state},data=false){
      state.resetName = data
      console.log('changeResetName',state);
    },
    // 添加使用题型
    questionId({state},data=-1){
      state.questionId = data
      console.log('questionId',state);
    },
    // 获取bpto题列表
    getBptoQuestions(vuex, params = {}) {
      return request({
        url: `${conUrl}/bpto/survey/${params.sn}/questions`,
        method: 'GET',
        params,
      });
    },
    // 场景详情
    getBptoScenesDet(vuex, params = {}) {
      return request({
        url: `${conUrl}/bpto/survey/scene/${params.scene_id}`,
        method: 'GET',
        params,
      });
    },
    // 获取bpto选项列表
    getBptoScenesOptions(vuex, params = {}) {
      return request({
        url: `${conUrl}/bpto/survey/${params.sn}/scene/${params.question_index}/options`,
        method: 'GET',
        params,
      });
    },
    // 创建场景
    postBptoScene(vuex, data = {}) {
      return request({
        url: `${conUrl}/bpto/survey/${data.sn}/scene`,
        method: 'POST',
        data,
      });
    },
    // 修改场景标题
    putScene(vuex, data = {}) {
      return request({
        url: `${conUrl}/bpto/survey/scene/${data.scene_id}`,
        method: 'PUT',
        data,
      });
    },
    // 删除场景
    delScene(vuex, data = {}) {
      return request({
        url: `${conUrl}/bpto/survey/scene/${data.scene_id}`,
        method: 'DELETE',
        data,
      });
    },
    // 场景列表
    getSurveyScenes(vuex, params = {}) {
      return request({
        url: `${conUrl}/bpto/survey/${params.sn}/scenes`,
        method: 'GET',
        params,
      });
    },
    // 获取锚定值
    getSurveyPriceAvg(vuex, params = {}) {
      return request({
        // url: `${conUrl}/bpto/survey/scene/question/${params.question_id}/price_avg`,
        url: `${conUrl}/bpto/survey/${params.sn}/scene/question/${params.question_index}/price_avg`,
        method: 'GET',
        params,
      });
    },
    // 取消计算
    putStatus(vuex, data = {}) {
      return request({
        url: `${conUrl}/bpto/survey/scene/${data.scene_id}/status`,
        method: 'PUT',
        data,
      });
    },
    // 重新计算
    putRecalculate(vuex, data = {}) {
      return request({
        url: `${conUrl}/bpto/survey/scene/${data.scene_id}/recalculate`,
        method: 'PUT',
        data,
      });
    },
    // 批量删除场景
    postBptoSceneDel(vuex, data = {}) {
      return request({
        url: `${conUrl}/bpto/survey/scenes/del`,
        method: 'POST',
        data,
      });
    },
    // 数据下载
    getSceneDownload(vuex, params = {}) {
      return request({
        url: `${conUrl}/bpto/survey/scene/${params.scene_id}/download`,
        method: 'GET',
        params,
      });
    },
    // 标题判重
    getCheckTitle(vuex, params = {}) {
      return request({
        url: `${conUrl}/bpto/surveys/${params.sn}/scenes/check_title/${params.title}`,
        method: 'GET',
        params,
      });
    },
  }
}