import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import './font/iconfont.css';
import installAntDesign from "./plugins/ant-design";
import VueParticles from 'vue-particles';
import mitt from 'mitt';
import lazyPlugin from 'vue3-lazy'
import NutBig from "@nutui/nutui-bingo";
import "@nutui/nutui-bingo/dist/style.css";
import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
const emitter = mitt();
const app = createApp(App);

app.use(VXETable)
app.use(VueParticles);
app.use(NutBig);
app.use(lazyPlugin, {})
app.directive('click-outside', {
  // 当被绑定的元素挂载到 DOM 中时……
  mounted(el, binding) {
    function documentHandler(e) {
      // 这里判断点击的元素是否是本身，是本身，则返回
      if (!el.contains(e.target)) {
        // 判断点击的位置是否是滚动条，不是，则调用
        const hasScroll = e.srcElement.scrollWidth > e.srcElement.clientWidth;
        if (hasScroll) {
          if (e.offsetY < (e.target.offsetHeight - 6)) {
            // 判断指令中是否绑定了函数
            if (binding.value) {
              // 如果绑定了函数 则调用那个函数，此处binding.value就是handleClose方法
              binding.value(e);
            }
          }
          return
        }
        // 判断指令中是否绑定了函数
        if (binding.value) {
          // 如果绑定了函数 则调用那个函数，此处binding.value就是handleClose方法
          binding.value(e);
        }
      }
    }
    // 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
    el.__vueClickOutside__ = documentHandler;
    document.getElementById("app").addEventListener("mousedown", documentHandler, true);
  },
  unmounted(el) {
    // 解除事件监听
    document.getElementById("app").removeEventListener('mousedown', el.__vueClickOutside__, true)
    delete el.__vueClickOutside__
  }
})
installAntDesign(app);
app.config.globalProperties.emitter = emitter;
app.use(store).use(router).mount("#app");

console.log('更新');

