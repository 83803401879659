import request from '@/utils/request'
import qs from 'qs'
const baseURL = process.env.NODE_ENV === 'production' ? '' : '/api';
// 公共地址路径
const console = '/console'
export default {
  namespaced: true,
  state:{
    upload:`${baseURL}/api${console}/contacts_import`,
    name:'联系人与邮件投放/短信投放'
  },
  actions:{
    // 联系人分组列表
    getGroupsList(vuex, params = {}) {
      return request({
        url: `${console}/contact/groups`,
        method: 'GET',
        params,
      });
    },
    // 添加样本信息
    addContacts(vuex, data = {}) {
      return request({
        url: `${console}/contacts`,
        method: 'POST',
        data
      });
    },
    // 新建联系人分组
    getGroupList(vuex, data = {}) {
      return request({
        url: `${console}/contact/group`,
        method: 'POST',
        data
      });
    },
    // 移除联系人
    removelContactsId(vuex, data = {}) {
      return request({
        url: `${console}/contact/group/remove`,
        method: 'POST',
        data,
      });
    },
    // 删除联系人分组
    delGroupId(vuex, data = {}) {
      return request({
        url: `${console}/contact/${data.id}/group`,
        method: 'DELETE',
        // data,
      });
    },
    // 重命名联系人分组
    putContactName(vuex, data = {}) {
      data = {...data}
      const nId = {...data}.id
      delete data.id
      delete data.cascader
      return request({
        url: `${console}/contact/${nId}/group`,
        method: 'PUT',
        data,
      });
    },
    // 联系人列表
    getContactsList(vuex, params = {}) {
      return request({
        url: `${console}/contacts`,
        method: 'GET',
        params,
      });
    },
    // 编辑联系人
    putContactsId(vuex, data = {}) {
      data = {...data}
      const nId = {...data}.id
      delete data.id
      delete data.cascader
      return request({
        url: `${console}/contacts/${nId}`,
        method: 'PUT',
        data,
      });
    },
    // 删除分析
    delContactsId(vuex, data = {}) {
      return request({
        url: `${console}/contacts/${data.id}`,
        method: 'DELETE',
        // data,
      });
    },
    // 模板下载
    getTemplate(vuex, params = {}) {
      return request({
        url: `${console}/template/2`,
        method: 'GET',
        responseType: 'arraybuffer'
      });
    },
    // 问卷模板下载
    getWordTemplate(vuex, params = {}) {
      return request({
        url: `${console}/template/3`,
        method: 'GET',
        responseType: 'arraybuffer'
      });
    },
    // 联系人批量导入
    postContactsImport(vuex, data = {}) {
      return request({
        url: `${console}/contacts_import`,
        method: 'POST',
        data
      });
    },
    // word上传
    postSurveyWordImport(vuex, data = {}) {
      return request({
        url: `${console}/survey_word_import`,
        method: 'POST',
        data
      });
    },
    // 获取邮件短信默认信息
    getShortMessage(vuex, params = {}) {
      return request({
        url: `${console}/short_message`,
        method: 'GET'
      });
    },
    // 问卷发布
    postSurveyPublishes(vuex, data = {}) {
      return request({
        url: `${console}/survey_publishes`,
        method: 'POST',
        data
      });
    },
    // 投放列表
    getLaunchLists(vuex, params = {}) {
      return request({
        url: `${console}/surveys/${params.sn}/releases`,
        method: 'GET',
        params
      });
    },
  }
}