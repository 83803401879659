import request from '@/utils/request'
export default {
  namespaced: true,
  state: {},
  actions: {

    heads (vuex, param = {}) {
      return request({
        url: `/console/survey_publishes/${param.sn}/publish_statistics/heads`,
        method: 'GET',
      });
    },
    // 问卷发布-发布统计-配额进度
    quotaProgress (vuex, params = {}) {
      return request({
        url: `/console/survey_publishes/${params.sn}/publish_statistics/quota_progress`,
        method: 'GET',
        params
      });
    },
  }
}