const MarketManage = () => import(/* webpackChunkName: 'home' */ '@views/TempMarket/Index.vue');

const router = [
  {
    path: 'market',
    name: 'Market',
    meta: { title: '模板库' },
    component: MarketManage,
  },
];
export default router;
