const routes = [
  {
    path: '/error',
    component: () => import(/* webpackChunkName: "error" */ '@/views/Error/Index.vue'),
    meta: { noRedirectLogin: true},
    children: [
      {
        path: '',
        redirect: '/error/404',
      },
      {
        path: '403',
        component: () => import(/* webpackChunkName: "error" */ '@/views/Error/403.vue'),
      },
      {
        path: '404',
        component: () => import(/* webpackChunkName: "error" */ '@/views/Error/404.vue'),
      },
      {
        path: '500',
        component: () => import(/* webpackChunkName: "error" */ '@/views/Error/500.vue'),
      },
    ],
  },
  {
    path: '/**',
    redirect: '/error/404',
  },
];

export default routes;
