import request from '@/utils/request'
import qs from 'qs'
// 公共地址路径
const conUrl = '/console'
export default {
  namespaced: true,
  state:{
    selectKeys:[],
    dataFilterInfo:{},
    exportInfo:{}
  },
  actions:{
    exportInfo({state},data){
      state.exportInfo = data
    },
    // 题目答案数据清洗
    cleanAnswer({state},{answer,question}){
      console.log('开始数据清洗',answer);
      let newChild = []
      // 判断是否有修改过的题型
      let isFlag = false
      for (let i = 0; i < answer.child.length; i++) {
        const el = answer.child[i];
        let flag = true
        let questionList = []
        question.map(cel=>{
          questionList.push(cel.question_index)
          if(cel.question_index ===el.question_index&& cel.question_type !== el.question_type){
            flag = false
            isFlag = true
          }
        })
        if(questionList.length>0&&!questionList.includes(el.question_index)){
          flag = false
          isFlag = true
        }
        if(flag){
          newChild.push(el)
        }
      }
      if(newChild.length>0){
        answer.child = newChild
      }else{
        answer.child = [{}]
      }
      return {answer,isFlag}
    },
    selectKeys({state},data){
      state.selectKeys = data
    },
    dataFilterInfo({state},data){
      state.dataFilterInfo = data
    },
    // 投放列表
    getSurveysAnswers(vuex, data = {}) {
      if(data.answer&&typeof data.answer =='object'){
        data.answer = JSON.stringify(data.answer)
      }
      console.log('筛选列表数据',data);
      return request({
        url: `${conUrl}/surveys/${data.sn}/answers`,
        method: 'POST',
        data
      });
    },
    getSurveysHead(vuex, param = {}) {
      return request({
        url: `${conUrl}/surveys/${param.sn}/answers/heads`,
        method: 'GET',
        param
      });
    },
    // 数据明细-导出pdf|zip
    getSurveysAnswersExport(vuex, data = {}) {
      return request({
        url: `${conUrl}/surveys/${data.sn}/answers_export`,
        method: 'POST',
        data
      });
    },
    // 数据明细-下载
    getSurveysAnswersDown(vuex, data = {}) {
      return request({
        url: `${conUrl}/surveys/${data.sn}/answers_download`,
        method: 'POST',
        data
      });
    },
    // 数据明细-回收站
    delSurveysAnswers(vuex, data = {}) {
      return request({
        url: `${conUrl}/surveys/${data.sn}/answers`,
        method: 'DELETE',
        data
      });
    },
    // 筛选方案列表
    getTemplateExport(vuex, param = {}) {
      return request({
        url: `${conUrl}/surveys/${param.sn}/template_export`,
        method: 'GET',
        param
      });
    },
    // 导入作答信息
    postAnswersImport(vuex, data = {}) {
      let sn = data.get("sn")
      return request({
        url: `${conUrl}/surveys/${sn}/answers_import`,
        method: 'POST',
        data
      });
    },
    // 筛选方案列表
    getSurveysAnswerFilter(vuex, param = {}) {
      return request({
        url: `${conUrl}/surveys/${param.sn}/answer_filter`,
        method: 'GET',
        param
      });
    },
    // 筛选方案新增
    postSurveysAnswers(vuex, data = {}) {
      return request({
        url: `${conUrl}/surveys/${data.sn}/answer_filter`,
        method: 'POST',
        data
      });
    },
    // 筛选方案复制
    postSurveysAnswersClone(vuex, data = {}) {
      return request({
        url: `${conUrl}/surveys/${data.sn}/answer_filter/${data.id}`,
        method: 'POST',
        data
      });
    },
    // 筛选方案修改
    putSurveysAnswers(vuex, data = {}) {
      return request({
        url: `${conUrl}/surveys/${data.sn}/answer_filter/${data.id}`,
        method: 'PUT',
        data
      });
    },
    // 筛选方案置顶
    putSurveysAnswersTop(vuex, data = {}) {
      return request({
        url: `${conUrl}/surveys/${data.sn}/answer_filter/${data.id}/top`,
        method: 'PUT',
        data
      });
    },
    // 筛选方案删除
    delSurveysAnswersTop(vuex, data = {}) {
      return request({
        url: `${conUrl}/surveys/${data.sn}/answer_filter/${data.id}`,
        method: 'DELETE',
        data
      });
    },
  }
}