// import CommonApi from '../../api/api';
import request from "@/utils/request";
import {
  A_COMMON_GET_QUESTIONINFO,
  A_COMMON_SET_QUESTIONINFO,
  A_COMMON_GET_QUESSAVEPARAM,
  A_COMMON_SET_QUESSAVEPARAM,
  A_COMMON_GET_ACTIVEQUESTION,
  A_COMMON_SET_ACTIVEQUESTION,
  A_COMMON_GET_WEBSOCKET,
  A_COMMON_SET_WEBSOCKET,
  A_COMMON_GET_TOKEN,
  A_COMMON_CLEAR_TOKEN,
  M_COMMON_SET_TOKEN,
  M_COMMON_SET_USERINFO,
  M_COMMON_GET_USERINFO,
  M_COMMON_SET_SURVEY_STATUS,
  M_COMMON_SET_SURVEY_NAME,
  M_COMMON_SET_TOKEN_UNAUTHORIZED,
  GET_FILE_TYPE,
  M_COMMON_SET_THEME_INFO,
  HEADER_IMG_INFO,
  DELETET_HEME_INFO_HEADIMGURL,
} from "../constance/constance.common";

export default {
  namespaced: true,
  state: {
    questionInfo: {}, // 所有题型列表
    quesSaveParam: {}, // 问题保存参数
    activeQuestion: {}, // 当前处于编辑态的题目
    file_type: [],
    token: localStorage.getItem("plantToken") ? localStorage.getItem("plantToken") : "",
    userInfo: localStorage.getItem("plantUserInfo")
      ? JSON.parse(localStorage.getItem("plantUserInfo"))
      : {},
    surveyStatus: undefined,
    surveyName: "",
    tokenUnauthorized: false,
    websocket: {}, // 长链接websocket实例
    themeInfo: {}, // 主题配置信息
    headerImgInfo: {}, //皮肤头图,
    initHomeData: false, //首页数据更新
  },
  getters: {},
  mutations: {
    /** 获取题型列表 */
    [A_COMMON_SET_QUESTIONINFO](state, questionInfo) {
      state.questionInfo = JSON.parse(questionInfo);
    },
    /** 获取问题保存参数 */
    [A_COMMON_SET_QUESSAVEPARAM](state, quesSaveParam) {
      state.quesSaveParam = JSON.parse(quesSaveParam);
    },
    /** 获取当前选中题型 */
    [A_COMMON_SET_ACTIVEQUESTION](state, activeQuestion) {
      state.activeQuestion = JSON.parse(activeQuestion);
    },
    /** 获取websocket实例 */
    [A_COMMON_SET_WEBSOCKET](state, ws) {
      state.websocket = ws;
    },

    /** 获取token */
    [M_COMMON_SET_TOKEN](state, token) {
      state.token = token;
    },
    /** 获取用户信息 */
    [M_COMMON_SET_USERINFO](state, userInfo) {
      state.userInfo = JSON.parse(userInfo);
    },
    [M_COMMON_SET_SURVEY_STATUS](state, status) {
      state.surveyStatus = status;
    },
    [M_COMMON_SET_SURVEY_NAME](state, name) {
      state.surveyName = name;
    },
    [M_COMMON_SET_TOKEN_UNAUTHORIZED](state, status) {
      state.tokenUnauthorized = !!status;
    },
    [GET_FILE_TYPE](state, file_type) {
      state.file_type = file_type;
    },
    [M_COMMON_SET_THEME_INFO](state, status) {
      status.head_img_url = state.themeInfo.head_img_url || status.head_img_url;
      state.themeInfo = status;
    },
    [HEADER_IMG_INFO](state, status) {
      state.headerImgInfo = status;
    },
    [DELETET_HEME_INFO_HEADIMGURL](state) {
      state.themeInfo.head_img_url = "";
    },
  },
  actions: {
    // 修改用户信息
    userInfo({ state }, data) {
      state.userInfo = data;
    },
    initHomeData(vuex, data) {
      vuex.state.initHomeData = data;
    },
    /**题型列表 */
    [A_COMMON_GET_QUESTIONINFO]({ commit }, questionInfo) {
      commit(A_COMMON_SET_QUESTIONINFO, questionInfo);
    },
    /**问题保存参数 */
    [A_COMMON_GET_QUESSAVEPARAM]({ commit }, quesSaveParam) {
      commit(A_COMMON_SET_QUESSAVEPARAM, quesSaveParam);
    },
    /**选中题型 */
    [A_COMMON_GET_ACTIVEQUESTION]({ commit }, activeQuestion) {
      commit(A_COMMON_SET_ACTIVEQUESTION, activeQuestion);
    },
    /**websocket */
    [A_COMMON_GET_WEBSOCKET]({ commit }, ws) {
      commit(A_COMMON_SET_WEBSOCKET, ws);
    },
    /* 获取用户token */
    [A_COMMON_GET_TOKEN]({ commit, dispatch }, token) {
      if (token) {
        console.log(dispatch);
        commit(M_COMMON_SET_TOKEN, token);
      }
    },
    [A_COMMON_CLEAR_TOKEN]: {
      root: true,
      handler({ commit }) {
        commit(M_COMMON_SET_TOKEN, "");
        localStorage.removeItem("plantToken");
        return true;
      },
    },
    /* 获取当前登录用户信心 */
    [M_COMMON_GET_USERINFO]({ commit, dispatch }, userInfo) {
      if (userInfo) {
        console.log(dispatch);
        commit(M_COMMON_SET_MENUS, userInfo);
      }
    },
    [GET_FILE_TYPE]({ commit, dispatch }, file_type) {
      if (file_type) {
        console.log(dispatch);
        commit(GET_FILE_TYPE, file_type);
      }
    },
    // 登记联系人
    postCommunitiesContacts(vuex, params = {}) {
      return request({
        url: `/console/communities/${params.community_id}/contacts`,
        method: "POST",
        params,
      });
    },
    // 前端下载
    fileDown(vuex, { fileURL, fileName }) {
      const req = new XMLHttpRequest();
      req.open("get", fileURL);
      req.responseType = "blob";
      req.onreadystatechange = (res) => {
        if (res.currentTarget.readyState == 4 && res.currentTarget.status == 200) {
          const url = window.URL.createObjectURL(res.currentTarget.response);
          let link = document.createElement("a");
          link.download = fileName || "defaultName";
          link.style.display = "none";
          link.href = url;
          // 触发点击
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      };
      req.send();
    },
    getYlToken() {
      try {
        return request({
          url: `/authorizations`,
          method: "POST",
          headers: {
            Authorization: localStorage.getItem("plantToken"),
            remoteIp: "127.0.0.1",
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
