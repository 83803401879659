const DataStatistics = () => import(/* webpackChunkName: 'home' */ '@views/DataStatistics/index.vue');

const router = [
  {
    path: 'datastatistics',
    name: 'datastatistics',
    meta: { title: '数据统计' },
    component: DataStatistics,
  },
];
export default router;
