import request from '@/utils/request'
import qs from 'qs'
// 公共地址路径
const console = '/console'
export default {
  namespaced: true,
  actions:{
    // 所有PSM题
    getPsmQuestionList(vuex, params = {}) {
      return request({
        url: `${console}/surveys/${params.sn}/psm/question-select`,
        method: 'GET'
      });
    },
    // 分析列表
    getPsmAnalyzeList(vuex, params = {}) {
      return request({
        url: `${console}/surveys/${params.sn}/psm/analyzes`,
        method: 'GET',
        params,
      });
    },
    // 开始分析
    postPsmAnalyzet(vuex, data = {}) {
      return request({
        url: `${console}/surveys/psm/analyze-run`,
        method: 'POST',
        data,
      });
    },
    // 更新psm焦点
    postPsmIntersection(vuex, data = {}) {
      return request({
        url: `${console}/surveys/psm/update-intersection`,
        method: 'POST',
        data,
      });
    },
    // 删除分析
    delPsmAnalyzeList(vuex, data = {}) {
      return request({
        url: `${console}/surveys/${data.sn}/${data.question_index}/psm/analyze`,
        method: 'DELETE',
        data,
      });
    },
    // PSM下载
    getPsmAnalyzeExport(vuex, params = {}) {
      return request({
        url: `${console}/surveys/psm/down-data`,
        method: 'POST',
        params
      });
    },
    // 获得分析结果
    getPsmAnalyzeResult(vuex, params = {}) {
      return request({
        url: `${console}/surveys/${params.sn}/${params.question_index}/psm/analyze_result`,
        method: 'GET'
      });
    },
  }
}