module.exports = {
  proxyUrl: process.env.VUE_APP_BASEURL,
  proxyUrlJava: process.env.VUE_APP_JAVA_DELiVERY_BASEURL,
  proxyUrlMessageCenter:process.env.VUE_APP_MESSAGE_CENTER,
  baseOss: process.env.VUE_APP_BASEOSS,
  redUrl: process.env.VUE_APP_REDURL,
  loginUrl: process.env.VUE_APP_LOGIN,
  socketUrl: process.env.VUE_APP_SOCKETURL,
  jsonpUrl: process.env.VUE_APP_JSONPURL,
};
