export default {
  namespaced: true,
  state: {
    // 项目管理分组名称
    projectName: '默认分组',
    // 模板库分组名称
    marketName: '默认分组',
    // 分组id
    groupId: 0,
    // 场景库版本权限
    authVersion: null,
    // 下载中心分组
    downloadGroupId: 0,
  },
  getters: {},
  mutations: {},
  actions: {
    changeProjectName ({ state }, name) {
      state.projectName = name
    },
    changeGroupId ({ state }, name) {
      state.groupId = name
    },
    changMarketName ({ state }, name) {
      state.marketName = name
    },
    changeAuthVersion ({ state }, num) {
      state.authVersion = num
    },
    changeDownloadGroupId ({ state }, id) {
      state.downloadGroupId = id
    },

  },
};
