import request from '@/utils/request'
import qs from 'qs'
// 公共地址路径
const console = '/console'
export default {
  namespaced: true,
  actions:{
    // 所有KANO题
    getKanoQuestionList(vuex, params = {}) {
      return request({
        url: `${console}/surveys/${params.sn}/kano/questions`,
        method: 'GET',
        // params,
      });
    },
    // 分析列表
    getKanoAnalyzeListt(vuex, params = {}) {
      return request({
        url: `${console}/surveys/${params.sn}/kano/analyzes`,
        method: 'GET',
        params,
      });
    },
    // 开始分析
    postKanoAnalyzet(vuex, data = {}) {
      return request({
        url: `${console}/surveys/kano/analyze`,
        method: 'POST',
        data,
      });
    },
    // 删除分析
    delKanoAnalyzeList(vuex, data = {}) {
      return request({
        url: `${console}/surveys/${data.sn}/${data.question_index}/kano/analyze`,
        method: 'DELETE',
        data,
      });
    },
    // KANO下载
    getKanoAnalyzeExport(vuex, params = {}) {
      return request({
        url: `${console}/surveys/${params.sn}/${params.question_index}/kano/analyze_export`,
        method: 'GET',
        params,
      });
    },
    // 获得分析结果
    getKanoAnalyzeResult(vuex, params = {}) {
      return request({
        url: `${console}/surveys/${params.sn}/${params.question_index}/kano/analyze_result`,
        method: 'GET',
        params,
      });
    },
     // 上传文件
    postDocumentImport(vuex, data = {}) {
      return request({
        url: `${console}/document_import`,
        method: 'POST',
        data,
      });
    },
  }
}