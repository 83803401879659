import request from '@/utils/request4'
// 公共地址路径
const conUrl = '/console'
export default {
  namespaced: true,
  state: {
    selectKeys: [],
  },
  mutations: {},
  actions: {
    selectKeys ({ state }, data) {
      state.selectKeys = data
    },
    // 部门列表
    getDeptslist (vuex, params = {}) {
      return request({
        url: `${conUrl}/surveys/data-boards/depts`,
        method: 'GET',
        params,
      });
    },
    // 事业部列表
    getBusinesslist (vuex, params = {}) {
      return request({
        url: `${conUrl}/surveys/data-boards/business`,
        method: 'GET',
        params,
      });
    },
    //问卷列表
    getDataboards (vuex, params = {}) {
      return request({
        url: `${conUrl}/surveys/data-boards/list`,
        method: 'GET',
        params,
      });
    },
    //标签列表 
    getTagsList () {
      return request({
        method: "GET",
        url: `/console/survey_tags`,
      });
    },
    //数据下载 
    download (data) {
      return request({
        method: "GET",
        url: `${conUrl}/surveys/data-boards/statistics/download`,
        data
      });
    }
  }
}