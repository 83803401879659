import 'ant-design-vue/dist/antd.less';
import {
  Anchor,
  Button,
  Checkbox,
  ConfigProvider,
  DatePicker,
  Image,
  Input,
  InputNumber,
  Form,
  message,
  Modal,
  Radio,
  RadioGroup,
  Rate,
  Select,
  Spin,
  Switch,
  Table,
  TimePicker,
  Tooltip,
  Dropdown,
  Menu,
  Row,
  Col,
  Pagination,
  Drawer,
  Popover,
  Skeleton,
  TreeSelect,
  Upload,
  Slider,
  Progress,
  Popconfirm,
  Tag,
  Tabs,
  Tree,
  Steps,
  Divider,
  Cascader,
  Empty,
  Alert
} from 'ant-design-vue';

export default (app) => {
  app.use(Anchor);
  app.use(Button);
  app.use(Checkbox);
  app.use(ConfigProvider);
  app.use(DatePicker);
  app.use(Image);
  app.use(Input);
  app.use(InputNumber);
  app.use(Form);
  app.use(Modal);
  app.use(Radio);
  app.use(RadioGroup);
  app.use(Rate);
  app.use(Select);
  app.use(Spin);
  app.use(Switch);
  app.use(Table);
  app.use(TimePicker);
  app.use(Tooltip);
  app.use(Dropdown);
  app.use(Menu);
  app.use(Row);
  app.use(Col);
  app.use(Pagination);
  app.use(Drawer);
  app.use(Popover);
  app.use(Skeleton);
  app.use(TreeSelect);
  app.use(Upload);
  app.use(Progress);
  app.use(Slider);
  app.use(Popconfirm);
  app.use(Tag);
  app.use(Tabs);
  app.use(Tree);
  app.use(Steps);
  app.use(Divider);
  app.use(Cascader);
  app.use(Empty);
  app.use(Alert);
  
  message.config({
    maxCount: 1
  })
  app.config.globalProperties.$message = message;
};
