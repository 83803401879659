import { createRouter, createWebHashHistory } from "vue-router";
import routes from "./modules";
import ProjectManage from "./router.projectManage";
import TemplateMarket from "./route.templateMarket"
import Contact from "./route.contact";
import DocumentLibrary from "./route.documentLibrary";
import DataStatistics from "./route.datastatistics";



const constantRoutes = [
  // {
  //   path: '/redirect',
  //   component: Layout,
  //   hidden: true,
  //   children: [
  //     {
  //       path: '/redirect/:path(.*)',
  //       component: () => import('@/views/redirect/index')
  //     }
  //   ]
  // },
  {
    path: '/:catchAll(.*)',
    redirect: "/error/404",
    meta: { noRedirectLogin: true },
  },
  {
    path: "/",
    redirect: "/home"
  },

  {
    path: "/heartbeat",
    component: () => import(/* webpackChunkName: 'heartbeat' */ "@views/Heartbeat/Index.vue"),
  },
  {
    path: "/home",
    name: "home",
    redirect: "/home/project",
    component: () => import(/* webpackChunkName: 'home' */ "@views/Home/Index.vue"),
    children: [...ProjectManage, ...TemplateMarket, ...Contact, ...DocumentLibrary,...DataStatistics]
  },
  {
    path: "/luck",
    name: "luck",
    component: () => import(/* webpackChunkName: "planet" */ "../views/planetDesign/redpacket/luck.vue")
  }, {
    path: "/addinfor",
    name: "addinfor",
    component: () => import(/* webpackChunkName: "planet" */ "../views/planetDesign/redpacket/addinfor.vue")
  },
  {
    path: "/prize",
    name: "prize",
    component: () => import(/* webpackChunkName: "planet" */ "../views/planetDesign/redpacket/prize.vue")
  },
  {
    path: "/succeed",
    name: "succeed",
    component: () => import(/* webpackChunkName: "planet" */ "../views/planetDesign/redpacket/succeed.vue")
  },
  {
    path: "/login",
    name: "login",
    component: () => import(/* webpackChunkName: 'login' */ "@views/Login/Login.vue")
  },
  {
    path: "/loginInvite",
    name: "loginInvite",
    component: () => import(/* webpackChunkName: 'login' */ "@views/TeamManage/TeamCenter/teamAdmin/login.vue")
  },
  {
    path: "/answer",
    name: "Answer",
    meta: { noRedirectLogin: true },
    component: () => import(/* webpackChunkName: "answer" */ "../views/Answer/Index.vue")
  },
  {
    path: "/preview",
    name: "Preview",
    component: () => import(/* webpackChunkName: "preview" */ "../views/Answer/Preview.vue")
  },
  {
    path: "/survey",
    name: "Survey",
    redirect: "/survey/planet",
    component: () => import(/* webpackChunkName: "survey" */ "../views/survey/index.vue"),
    children: [
      {
        path: "planet",
        name: "planet",
        redirect: "/survey/planet/design",
        component: () => import(/* webpackChunkName: "planet" */ "../views/planetDesign/Index.vue"),
        children: [
          {
            path: "design",
            name: "design",
            meta: { showPreview: true, showPublish: true, showDownload: true, },
            component: () => import(/* webpackChunkName: "planet" */ "../views/planetDesign/Design/DesignContent.vue")
          },
          {
            path: "logical",
            name: "logical",
            meta: { showPublish: true, showDownload: true, },
            component: () => import(/* webpackChunkName: "planet" */ "../views/planetDesign/Logical/Index.vue")
          },
          {
            path: "test",
            meta: { showPublish: true, showDownload: true, },
            component: () => import(/* webpackChunkName: "planet" */ "../views/planetDesign/PlanetTest.vue")
          },
          {
            path: "answer-setting",
            meta: { showPublish: true, showDownload: true, },
            component: () => import(/* webpackChunkName: "planet" */ "../views/planetDesign/AnswerSetting.vue")
          },
          {
            path: "theme",
            meta: { showPublish: true, showPreview: true, showDownload: true, },
            component: () => import(/* webpackChunkName: "planet" */ "../views/planetDesign/Theme/index.vue")
          },
          {
            path: "redpacket",
            meta: { showPublish: true, showPreview: true, showDownload: true, },
            component: () => import(/* webpackChunkName: "planet" */ "../views/planetDesign/redpacket/index.vue")
          },
          {
            path: "addset",
            meta: { showPublish: true, showPreview: true, showDownload: true, },
            component: () => import(/* webpackChunkName: "planet" */ "../views/planetDesign/redpacket/addset.vue")
          }
        ]
      },
      {
        path: "schedule",
        name: "schedule",
        redirect: "/survey/schedule/index",
        component: () => import(/* webpackChunkName: "planet" */ "../views/schedule/index.vue"),
        children: [
          {
            path: "recycle",
            name: "recycle",
            meta: { showPreview: true, showPublish: true, showDownload: true, },
            component: () => import(/* webpackChunkName: "planet" */ "../views/schedule/recycle.vue")
          },]
      },
      {
        path: "analyse",
        name: "analyse",
        redirect: { name: 'Diagram' },
        meta: { keepAlive: false },
        component: () => import(/* webpackChunkName: "analyse" */ "@/views/DataAnalyse/index"),
        children: [
          {
            path: "data-particulars",
            name: "DataParticulars",
            meta: { keepAlive: true },
            meta: { showPublish: false, showPreview: false, showShare: true, showDownload: true },
            component: () => import(/* webpackChunkName: "analyse" */ "@/views/DataAnalyse/particulars/list")
          },
          {
            path: "diagram",
            name: "Diagram",
            meta: { keepAlive: true, showDownload: true },

            component: () => import(/* webpackChunkName: "analyse" */ "@/views/DataAnalyse/diagram/test")
          },
          {
            path: "test",
            name: "test",
            meta: { keepAlive: true },

            component: () => import(/* webpackChunkName: "analyse" */ "@/views/DataAnalyse/diagram/test")
          },
          {
            path: "kndiagram",
            name: "knDiagram",
            component: () => import(/* webpackChunkName: "analyse" */ "@/views/DataAnalyse/kndiagram/index"),
            redirect: "index",
            children: [{
              name: "KANO分析",
              path: "index",
              component: () => import(/* webpackChunkName: "analyse" */ "@/views/DataAnalyse/kndiagram/questions/Kano"),
            },
            {
              name: "PSM分析",
              path: "psm",
              component: () => import(/* webpackChunkName: "analyse" */ "@/views/DataAnalyse/kndiagram/questions/psm"),
            },
            {
              name: "MXD分析",
              path: "mxd",
              component: () => import(/* webpackChunkName: "analyse" */ "@/views/DataAnalyse/kndiagram/questions/mxd"),
            },
            ],
            // {
            //   name: "分析模型",
            //   path: "index",
            //   component: () => import(/* webpackChunkName: "analyse" */ "@/views/DataAnalyse/kndiagram/index"),
            // },
            // {
            //   name: "市场模拟",
            //   path: "index",
            //   component: () => import(/* webpackChunkName: "analyse" */ "@/views/DataAnalyse/kndiagram/index"),
            // }

          },
          {
            path: "bi",
            name: "bi",
            component: () => import(/* webpackChunkName: "publish" */ "@/views/Publish/bi")
          },
          {
            // BPTO模拟
            path: "bptoAnalog",
            name: "bptoAnalog",
            meta: { keepAlive: true },
            component: () => import(/* webpackChunkName: "analyse" */ "@/views/DataAnalyse/bptoAnalog/index")
          },
          {
            path: "crosstabs",
            name: "crosstabs",
            meta: { keepAlive: true, showDownload: true },
            component: () => import(/* webpackChunkName: "analyse" */ "@/views/DataAnalyse/crosstabs/index.vue")
          },
          {
            path: "recycleBin",
            name: "recycleBin",
            component: () => import(/* webpackChunkName: "analyse" */ "@/views/DataAnalyse/recycleBin/index"),
            redirect: "detailData",
            children: [{
              name: "数据明细",
              path: "detailData",
              // meta: {title:"数据明细" },
              component: () => import(/* webpackChunkName: "analyse" */ "@/views/DataAnalyse/recycleBin/binList/detailData"),
            }
            ],
          },

        ]
      },
      {
        path: "publish",
        name: "publish",
        redirect: "/survey/publish/link",
        component: () => import(/* webpackChunkName: "publish" */ "@/views/Publish/index"),
        children: [
          {
            path: "link-source",
            name: "link-source",
            meta: { showPreview: false, showPublish: false, keepAlive: true, showShare: true },
            component: () => import(/* webpackChunkName: "publish" */ "@/views/Publish/link")
          },
          {
            path: "link",
            name: "link",
            meta: { showPreview: false, showPublish: false, keepAlive: true, showShare: true },
            component: () => import(/* webpackChunkName: "publish" */ "@/views/Publish/launch-center/launch-task/index")
          },
          {
            path: "create",
            name: "create",
            meta: { showPreview: false, showPublish: false, keepAlive: true, showShare: true },
            component: () => import(/* webpackChunkName: "publish" */ "@/views/Publish/launch-center/launch-task/create")
          },
          // {
          //   path: "link",
          //   name: "link",
          //   meta: { showPreview: false, showPublish: false, keepAlive: true, showShare: true },
          //   component: () => import(/* webpackChunkName: "publish" */ "@/views/Publish/link")
          // },
          {
            path: "analyst",
            name: "analyst",
            meta: { showPreview: false, showPublish: false, keepAlive: true, showShare: true },
            component: () => import(/* webpackChunkName: "publish" */ "@/views/Publish/analyse")
          },
          {
            path: "api",
            name: "api",
            component: () => import(/* webpackChunkName: "publish" */ "@/views/Publish/api")
          },
          {
            path: "email",
            name: "email",
            component: () => import(/* webpackChunkName: "publish" */ "@/views/Publish/message/record"),
          },
          {
            path: "census",
            name: "census",
            component: () => import(/* webpackChunkName: "publish" */ "@/views/Publish/census/census"),
          },
          {
            path: "emailRecord",
            name: "emailRecord",
            component: () => import(/* webpackChunkName: "publish" */ "@/views/Publish/email/email")
          },
          {
            path: "message",
            name: "message",
            component: () => import(/* webpackChunkName: "publish" */ "@/views/Publish/message/record")
          },
          {
            path: "messageRecord",
            name: "messageRecord",
            component: () => import(/* webpackChunkName: "publish" */ "@/views/Publish/message/message")
          },
          {
            path: "sample",
            name: "sample",
            component: () => import(/* webpackChunkName: "publish" */ "@/views/Publish/sample/sample")
          },
          {
            path: "activity",
            name: "activity",
            component: () => import(/* webpackChunkName: "publish" */ "@/views/Publish/activity/activity")
          },
          {
            path: "group",
            name: "group",
            component: () => import(/* webpackChunkName: "publish" */ "@/views/Publish/temp")
          },

          {
            path: "flush",
            name: "flush",
            component: () => import(/* webpackChunkName: "publish" */ "@/views/Publish/flush")
          },
        ]
      },
    ]
  },
  {
    path: "/InviteLogin",
    name: "InviteLogin",
    component: () => import(/* webpackChunkName: 'InviteLogin' */  "../views/TeamManage/InviteLogin.vue")
  },
  {
    path: "/team-manage",
    name: "TeamManage",
    component: () => import(/* webpackChunkName: 'home' */  "../views/TeamManage/index.vue"),
    children: [
      {
        path: "user-center",
        name: "userCenTer",
        component: () => import(/* webpackChunkName: 'home' */  "../views/TeamManage//UserCenter/index.vue"),
        // children: [
        //   {
        //     path: "design",
        //     name: "design",
        //     meta: { showPreview: true, showPublish: true, keepAlive: true },
        //     component: () => import(/* webpackChunkName: "planet" */ "../views/planetDesign/DesignContent.vue")
        //   },
        //   {
        //     path: "logical",
        //     name: "logical",
        //     component: () => import(/* webpackChunkName: "planet" */ "../views/planetDesign/PlanetLogical.vue")
        //   },
        //   {
        //     path: "test",
        //     name: "test",
        //     component: () => import(/* webpackChunkName: "planet" */ "../views/planetDesign/PlanetTest.vue")
        //   },
        //   {
        //     path: "answer-setting",
        //     meta: { showPublish: true },
        //     component: () => import(/* webpackChunkName: "planet" */ "../views/planetDesign/AnswerSetting.vue")
        //   },
        //   {
        //     path: "theme",
        //     meta: { showPublish: true, showPreview: true },
        //     component: () => import(/* webpackChunkName: "planet" */ "../views/planetDesign/Theme/index.vue")
        //   }
        // ]
      },
      {
        path: "team-center",
        name: "teamCenter",
        component: () => import(/* webpackChunkName: 'home' */  "../views/TeamManage//TeamCenter/index.vue")
      },
    ],
  },
  // 下载中心
  // {
  //   path: "/downloadCenter",
  //   name: "downloadCenter",
  //   component: () => import(/* webpackChunkName: "preview" */ "../views/DownloadCenter/index.vue")
  // },
  ...routes
];

export const asyncRoutes = [];
const router = createRouter({
  history: createWebHashHistory(),
  routes: constantRoutes
});
router.beforeEach((to, from, next) => {
  if (!to.meta.noRedirectLogin) {
    if (window.self === window.top) {
      // window.parent.location.href = 'https://yip-uat.dctest.digitalyili.com/login';
    }
  }
  next()
})
export default router;
