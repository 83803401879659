export const A_COMMON_GET_QUESTIONINFO = 'A_COMMON_GET_QUESTIONINFO';
export const A_COMMON_SET_QUESTIONINFO = 'A_COMMON_SET_QUESTIONINFO';

export const A_COMMON_GET_QUESSAVEPARAM = 'A_COMMON_GET_QUESSAVEPARAM';
export const A_COMMON_SET_QUESSAVEPARAM = 'A_COMMON_SET_QUESSAVEPARAM';

export const A_COMMON_GET_ACTIVEQUESTION = 'A_COMMON_GET_ACTIVEQUESTION';
export const A_COMMON_SET_ACTIVEQUESTION = 'A_COMMON_SET_ACTIVEQUESTION';

export const A_COMMON_GET_WEBSOCKET = 'A_COMMON_GET_WEBSOCKET';
export const A_COMMON_SET_WEBSOCKET = 'A_COMMON_SET_WEBSOCKET';

export const A_COMMON_GET_TOKEN = 'A_COMMON_GET_TOKEN';
export const A_COMMON_CLEAR_TOKEN = 'A_COMMON_CLEAR_TOKEN';
export const M_COMMON_SET_TOKEN = 'M_COMMON_SET_TOKEN';

export const M_COMMON_GET_USERINFO = 'M_COMMON_GET_USERINFO';
export const M_COMMON_SET_USERINFO = 'M_COMMON_SET_USERINFO';

export const M_COMMON_SET_SURVEY_STATUS = 'M_COMMON_SET_SURVEY_STATUS';
export const M_COMMON_SET_SURVEY_NAME = 'M_COMMON_SET_SURVEY_NAME';

export const M_COMMON_SET_TOKEN_UNAUTHORIZED = 'M_COMMON_SET_TOKEN_UNAUTHORIZED';


export const GET_FILE_TYPE = 'GET_FILE_TYPE';
export const M_COMMON_SET_THEME_INFO = 'M_COMMON_SET_THEME_INFO'
export const HEADER_IMG_INFO = 'HEADER_IMG_INFO'
export const DELETET_HEME_INFO_HEADIMGURL = 'DELETET_HEME_INFO_HEADIMGURL'

