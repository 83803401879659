const ProjectManage = () => import(/* webpackChunkName: 'home' */ '@views/ProjectManage/Index.vue');

const router = [
  {
    path: 'project',
    name: 'project',
    meta: { title: '问卷管理' },
    component: ProjectManage,
  },
];
export default router;
