import request from '@/utils/request'
import qs from 'qs'
const baseURL = process.env.NODE_ENV === 'production' ? '' : '/api';
// 公共地址路径
const console = '/console'
export default {
  namespaced: true,
  actions: {
    //查看排序方式
    sorts (vuex, params = {}) {
      return request({
        url: `${console}/document_sorts/${params.type}`,
        method: 'GET',
      });
    },

    //获取令牌
    getKey () {
      return {token:null}
      // return request({
      //   url: `/console/surveys/lottery_settings/token `,
      //   method: "get"
      // });
    },
    //通过答题sn获取问卷设计sn
    getanswer (vuex, params = {}) {
      return request({
        url: `${console}/answer_to_survey/${params.answer_sn} `,
        method: "get"
      });
    },
    // 文档我的空间，与我共享列表
    getDocumentsList (vuex, params = {}) {
      return request({
        url: `${console}/documents`,
        method: 'GET',
        params,
      });
    },
    // 重命名文件
    editname (vuex, params = {}) {
      return request({
        url: `${console}/documents/${params.sn}/name`,
        method: 'PUT',
        params,
      });
    },
    // 文档收藏列表
    getDocumentsList2 (vuex, params = {}) {
      return request({
        url: `${console}/document_favorites`,
        method: 'GET',
        params,
      });
    },
    // 最近文档列表
    getDocumentsList1 (vuex, params = {}) {
      return request({
        url: `${console}/document_logs`,
        method: 'GET',
        params,
      });
    },
    //获得签名
    wpsname (vuex, params = {}) {
      return request({
        url: `${console}/document/signature`,
        method: 'GET',
        params,
      });
    },
    // 删除文件
    Delete (vuex, params = {}) {
      return request({
        url: `${console}/documents/${params.sn}`,
        method: 'delete',
        params,
      });
    },
    // 收藏文件
    Putin (vuex, params = {}) {
      return request({
        url: `${console}/document_favorites/${params.sn}/status`,
        method: 'PUT'
      });
    },
    // 新建文件
    document (vuex, params = {}) {
      return request({
        url: `${console}/documents`,
        method: 'POST',
        params
      });
    },
    // 文件类型选项
    typeoption () {
      return request({
        url: `${console}/document_format`,
        method: 'get'
      });
    },
    /* 用户列表 */
    getUserList () {
      return request({
        url: `${console}/users`,
        method: 'get'
      });
    },

    // 复制文件
    copylist (vuex, params = {}) {
      return request({
        url: `${console}/documents/${params.sn}`,
        method: 'POST',
      });
    },
    //  // 可复制文件夹下拉选
    //  copy (vuex, params = {}) {
    //   return request({
    //     url: `${console}/documents/${params.sn}/copy`,
    //     method: 'GET'
    //   });
    // },
    // 查看wps文件
    viewwps (vuex, params = {}) {
      return request({
        url: `${console}/documents/${params.sn}`,
        method: 'get'
      });
    },
    // 可移动至文件夹下拉选
    movelist (vuex, params = {}) {
      return request({
        url: `${console}/documents/${params.sn}/move`,
        method: 'GET'
      });
    },
    // 可移动至文件夹下拉选
    movelist1 (vuex, params = {},) {
      return request({
        url: `${console}/documents/${params.sn2}/move`,
        method: 'GET',
        params
      });
    },
    // 移动文件
    movewjj (vuex, params) {
      return request({
        url: `${console}/documents/${params.snold}/move`,
        method: 'PUT',
        params
      });
    },
    // 下载文件
    export (vuex, params) {
      return request({
        url: `${console}/documents/${params.sn}/export`,
        method: 'GET',
      });
    },
    // 下载文件夹
    download (vuex, params) {
      return request({
        url: `${console}/documents/${params.sn}/download`,
        method: 'GET',
      });
    },
    // 上传文件
    up (vuex, params) {
      return request({
        url: `${console}/document_import`,
        method: 'POST',
        params
      });
    },

  }
}
