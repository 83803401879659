import axios from "axios";
import store from "@/store";
import router from "@/router/index";
import { message, Modal } from "ant-design-vue";
import { A_COMMON_CLEAR_TOKEN } from "@store/constance/constance.common";

const { proxyUrlJava } = require("@/config");
const baseURL = process.env.NODE_ENV === "production" ? proxyUrlJava : "/api";

// axios.defaults.withCredentials = true;

// create an axios instance
const service = axios.create({
  baseURL: `${baseURL}`, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    if (!config.headers) {
      config.headers.Accept = "application/json";
    }
    config.headers.Authorization = `${localStorage.getItem("plantToken")}`;
    if (!config.headers.remoteIp) {
      config.baseURL += "/api";
    }
    config.headers.remoteIp = localStorage.getItem("plantIp") || "127.0.0.1";
    // if (store.state.common.token) {
    //   config.headers['Login-Type'] = 'pc';
    //   config.headers.Authorization = `Bearer ${store.state.common.token}`;
    // }
    return config;
  },
  (error) => Promise.reject(error)
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    if (
      response.status === 200 ||
      response.status === 201 ||
      response.status === 202 ||
      response.status === 204
    ) {
      if (response.config.method === "put") {
        message.success("保存中...");
      }
      return Promise.resolve(response.data);
    }
    return Promise.reject(new Error(response.message || "Error"));
  },
  (error) => {
    // for debug
    if (error.response.status === 401) {
      const query = router.currentRoute.value.query;
      //关闭已弹出的所有弹框，防止弹框重叠
      Modal.destroyAll();
      store.dispatch(A_COMMON_CLEAR_TOKEN);
      window.parent.postMessage(
        {
          code: "301",
          params: {},
        },
        "*"
      );
      store.commit("common/M_COMMON_SET_TOKEN_UNAUTHORIZED", false);
    } else if (error.response.status === 403) {
      router.push({
        path: "/error/403",
      });
    } else if (error.response.status === 404) {
      router.push({
        path: "/error/404",
      });
    } else if (error.response.status === 500) {
      router.push({
        path: "/error/500",
      });
    } else {
      message.error(error.response.data?.message || "服务器错误");
    }
    return Promise.reject(error.response);
  }
);

export default service;
