const modulesFiles = require.context('./', true, /\.js$/);
const modules = [];

modulesFiles.keys()
.filter(dir => dir !== './index.js')
.reduce((ms, modulePath) => {
  const module = modulesFiles(modulePath).default;
  modules.push(...module);
  return module;
}, {});

export default [...modules];
