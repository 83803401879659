// import request from "@/utils/redRequest";
import request from '@/utils/request'
// 公共地址路径
const pubUrl = '/console'
export default {
  namespaced: true,
  actions: {
    //获取活动列表
    actLists (vuex, params) {
      params.project_id =1
      return request({
        url: `${pubUrl}/rp_activity/actLists`,
        method: "get",
        params,
      });
    },
    //活动状态变更
    actChangeStatus (vuex, data) {
      data.project_id =1
      return request({
        url: `${pubUrl}/rp_activity/changeStatus`,
        method: "post",
        data,
      });
    },
    //活动详情
    actDetail (vuex, params) {
      params.project_id =1
      return request({
        url: `${pubUrl}/rp_activity/actDetail/${params.activity_id}`,
        method: "get",
        params,
      });
    },
    //奖池统计
    prizePool (vuex, params) {
      params.project_id =1
      return request({
        url: `${pubUrl}/rp_lottery_logs/${params.activity_id}/prize_pool/${params.project_id}`,
        method: "get",
        params,
      });
    },
    //中奖列表
    getLotteryLogs (vuex, params) {
      params.project_id =1
      return request({
        url: `${pubUrl}/rp_lottery_logs`,
        method: "get",
        params,
      });
    },
     //下载中奖列表
     export (vuex, params) {
      params.project_id =1
      return request({
        url: `${pubUrl}/rp_lottery_logs/export/${params.project_id}`,
        method: "get",
        params,
      });
    },
    //作废奖品
    invalidPrizes (vuex, data) {
      data.project_id =1
      return request({
        url: `${pubUrl}/rp_lottery_logs/${data.log_id}/invalidPrizes`,
        method: "post",
        data,
      });
    },
    //发放奖品
    offeringPrizes (vuex, data) {
      data.project_id =1
      return request({
        url: `${pubUrl}/rp_lottery_logs/${data.log_id}/offeringPrizes`,
        method: "post",
        data,
      });
    },
    //活动新增
    actCreate (vuex, data) {
      data.project_id =1
      return request({
        url: `${pubUrl}/rp_activity/actCreate`,
        method: "post",
        data,
      });
    },
    //活动编辑
    actUpdate (vuex, data) {
      data.project_id =1
      return request({
        url: `${pubUrl}/rp_activity/actUpdate`,
        method: "post",
        data
      });
    },
    //获取前端抽奖九宫格数据
    priLists (vuex, params) {
      params.project_id =1
      return request({
        url: `${pubUrl}/rp_prizes/${params.activity_id}`,
        method: "get",
        params
      });
    },
    //前端开始抽奖
    beganToDraw (vuex, data) {
      data.project_id =1
      return request({
        url: `${pubUrl}/rp_lottery_log/began_to_draw`,
        method: "POST",
        data
      });
    },
    //完善中奖用户信息
    toImproveUserInformation (vuex, data) {
      data.project_id =1
      return request({
        url: `${pubUrl}/rp_lottery_log/to_improve_user_information`,
        method: "POST",
        data
      });
    }
  },
};
